<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      {{ this.mmsg }}
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js/crypto-js';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
        mmsg: "f6m station list:"
    }
  },
  mounted() {
    // var params=new URLSearchParams();
    // params.append('project',"f6m");
    // params.append('clustername',"ant-maibo-20230925");

    var title = "E29E1C240A072FBC06353064A6B207AA6964B6F6linkant";
    var t = new Date().getTime();
    var timestamp_ms = Math.round(t);
    var timestamp_ms_str = timestamp_ms.toString();
    var mytimeStamp = timestamp_ms_str.substring(9, 13);
    var source = title + mytimeStamp;
    var md5hash = CryptoJS.MD5(source);
    var localmd5 = md5hash.toString(CryptoJS.enc.Hex);
    var token = title + timestamp_ms_str + localmd5;
                
    this.$axios.post('https://fapi.gfanxvision.com/mes-api/mes/getStationFlow', {'project':"f6m",'clustername':"ant-maibo-20230925"},{
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'token': token,
            'timestamp': timestamp_ms_str
        }
        })
        .then(response => {
            // 处理响应数据
            console.log(response.data);
            this.mmsg= this.mmsg + JSON.stringify(response.data);
        })
        .catch(error => {
            // 处理请求错误
            console.log(error);
        });
    console.log(`The initial count is ${this.mmsg}.`)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
//引入axios
import axios from 'axios'


const app = createApp(App)
//设置成全局
app.config.globalProperties.$axios = axios;
axios.defaults.baseURL = '/api'
app.mount('#app')

<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <h1>{{ msg1 }}</h1>
  <HelloWorld msg="接口请求测试"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
    data() {
        return {
            msg1:"静态页面测试"
        }
    },
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
